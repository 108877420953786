<template>
<div class="img_box" :style="data.size">
  <!-- <img :src="props.src" class="cover_img object-cover"> -->
  <img v-lazyload="props.src" class="cover_img object-cover">
</div>
</template>

<script lang="ts" setup>
// 带圆角图片移入放大组件
import { reactive,computed } from "vue";
const data = reactive({
  size:{}
})
const props = defineProps({
  // 图片高度
  height:{
    type:Number,
    default:258
  },
  // 路径
  src:{
    type:String,
    default:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Flmg.jj20.com%2Fup%2Fallimg%2F1114%2F121420113514%2F201214113514-6-1200.jpg&refer=http%3A%2F%2Flmg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1670489277&t=47fa2384cfdf44f9222eca036b946a01'
  }
})
data.size = computed(()=>{
  return {
    width:'100%',
    height:props.height+'px'
  }
})
</script>

<style lang="less" scoped>
  .img_box{
    overflow: hidden;
    border-radius: 9px 9px 0 0 ;
    width: 100%;
    height: 258px;
    .cover_img{
      width: 100%;
      height: 100%;
      border-radius: 9px 9px 0 0 ;
      cursor: pointer;
      transition: linear 0.2s;
      background-image: url(@/assets/images/home_greypic_ico.png);
      background-size: 100% 100%;
    }
    .cover_img:hover{
      transform: scale(1.1);
    }
  }

</style>